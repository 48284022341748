<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Career Position
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="UX Designer ( Full time)"
            v-model="form.title" />
          <span class="text-danger" v-if="$v.form.title.$error">Title is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Excerpt</label>
                  <input type="text" v-model="form.excerpt" class="form-control" placeholder="Excerpt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
            </div>
            <div class="mb-15px">
              <label class="form-label">
                Description
              </label>
              <ckeditor v-model="form.description" :config="{
                delayDetached: true,
                autoParagraph: false,
                enterMode: 2
              }" />
              <span class="text-danger" v-if="$v.form.description.$error">Description is required</span>
            </div>
            <div class="mb-15px">
              <label class="form-label">Category</label>
              <v-select v-model="form.category" :options="categoryOptions" label="category_name" />
              <span class="text-danger" v-if="$v.form.category.$error">Category is required</span>
            </div>
            <div class="row">
              <div class="mb-15px">
                <label class="form-label">Location</label>
                <input type="text" class="form-control" v-model="form.location" placeholder="Jakarta - Indonesia" />
                <span class="text-danger" v-if="$v.form.location.$error">Location is required</span>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Job Type</label>
                  <select class="form-select" v-model="form.type">
                    <option v-for="(option, key) in optionsType" v-bind:value="option.id" :key="key"
                      v-text="option.type" />
                  </select>
                  <!-- <v-select v-model="form.type" :options="optionsType" label="type"/> -->
                  <span class="text-danger" v-if="$v.form.type.$error">Job type is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Level</label>
                  <select class="form-select" v-model="form.level">
                    <option v-for="(option, key) in optiosLevel" v-bind:value="option.id" :key="key"
                      v-text="option.level" />
                  </select>
                  <span class="text-danger" v-if="$v.form.level.$error"> Level is required</span>
                </div>
              </div>
            </div>
            <!-- <div class="mb-15px">
              <label class="form-label">Image Job Position 1280x550 (jpeg, jpg, png)</label>
              <UploadImage v-model="form.images.desktop" :src="form.images.desktopSrc" size="1000"
                @image-err-message="imgErrMessage" />
              <span class="text-danger" v-if="$v.form.images.desktop.$error">Image Job Position is required</span>
            </div> -->
            <div class="col-md-12">
              <label class="form-label">Main Image1</label>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <p class="mb-2">
                    (dekstop version : 1280x550 px, max. 1mb, jpeg, jpg, png)
                  </p>
                  <UploadImage v-model="form.images.desktop" :src="form.images.desktopSrc" size="1000"
                    @image-err-message="imgErrMessage" :dimensions="{ width: 1280, height: 550 }" />
                  <!-- <span class="text-danger" v-if="$v.page.images.desktop.$error">Dekstop version is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <p class="mb-2">
                    (mobile version : 300x600 px, max. 1mb, jpeg, jpg, png)
                  </p>
                  <UploadImage v-model="form.images.mobile" :src="form.images.mobileSrc" size="1000"
                    :dimensions="{ width: 300, height: 600 }" @image-err-message="imgErrMessage" />
                  <!-- <span class="text-danger" v-if="$v.page.images.mobile.$error">Mobile version is required</span> -->
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Dekstop Alt Text</label>
                  <input type="text" v-model="form.images.alt_desktop_image" class="form-control"
                    placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Mobile Alt Text</label>
                  <input type="text" v-model="form.images.alt_mobile_image" class="form-control" placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <panel title="SEO Information" variant="inverse" :is-collapse="true">
          <div class="mb-15px">
            <label class="form-label">Meta Title</label>
            <input type="text" v-model="form.meta.title" placeholder="Meta Title" class="form-control">
            <span class="text-danger" v-if="$v.form.meta.title.$error">Meta Title is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Description</label>
            <textarea class="form-control" rows="7" v-model="form.meta.description"
              placeholder="Meta Description"></textarea>
            <span class="text-danger" v-if="$v.form.meta.description.$error">Meta Description is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Keywords</label>
            <input-tag v-model="form.meta.keywords" class="form-control default"></input-tag>
            <small class="fw-normal text-muted">Separate with commas</small>
            <br>
            <span class="text-danger" v-if="$v.form.meta.keywords.$error">Meta Keywords is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Image <span class="fw-normal text-muted">(Optional)(jpeg, jpg,
                png)</span></label>
            <UploadImage v-model="form.meta.image" :src="form.meta.imageSrc" size="1000"
              @image-err-message="imgErrMessage" />
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Alt</label>
              <input type="text" v-model="form.meta.alt_image" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
        </panel>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue"
import { required } from 'vuelidate/lib/validators'
import CareerCategoryService from "@/service/careercategory.service";
import careerLevelService from "@/service/careerlevel.service";
import jobTypeCareerService from "@/service/jobtype.service";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadLevel()
    this.loadType()
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    this.loadCategory()
    return {
      customToolbar: defaultToolbarEditor,
      optionsType: [],
      categoryOptions: [],
      optiosLevel: ['Executive or Senior Management', 'Middle Management', 'First-level Management', 'Intermediate or Experienced (Senior Staf)', 'Entry Level'],
      form: {
        title: detail ? detail.title : null,
        description: detail ? detail.description : null,
        location: detail ? detail.location : null,
        type: detail ? detail.type : null,
        category: detail ? detail.category : null,
        excerpt: detail ? detail.excerpt : null,
        images: {
          desktop: detail ? detail.images.desktop : null,
          desktopSrc: detail ? detail.images.desktopSrc : '',
          alt: detail ? detail.images.alt : null,
        },
        meta: {
          title: detail ? detail.meta.title : null,
          description: detail ? detail.meta.description : null,
          keywords: detail ? detail.meta.keywords : [],
          image: detail ? detail.meta.image : null,
          imageSrc: detail ? detail.meta.imageSrc : ''
        },
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    'detailData'(newVal) {
      this.form = newVal
    }
  },
  methods: {
    doSave(status) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          form: this.form,
          status
        };
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }

    },
    loadCategory() {
      CareerCategoryService
        .getCareerCategoryList({})
        .then(({ data: { data } }) => {
          this.categoryOptions = data.CareerCategory
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    loadLevel() {
      this.isLoading = true;
      careerLevelService
        .getCareerLevelList(this.serverParams)
        .then(({ data: { data } }) => {
          this.optiosLevel = data.CareerLevel
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadType() {
      this.isLoading = true;
      jobTypeCareerService
        .getJobTypeList(this.serverParams)
        .then(({ data: { data } }) => {
          this.optionsType = data.CareerType
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  validations: {
    form: {
      title: { required },
      description: { required },
      location: { required },
      type: { required },
      category: { required },
      level: { required },
      excerpt: { required },
      images: {
        desktop: { required },
      },
      meta: {
        title: { required },
        description: { required },
        keywords: { required },
      },
    },
  }
};
</script>
