<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Page
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Page Title" v-model="page.title" />
          <span class="text-danger" v-if="$v.page.title.$error">Title is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Slug</label>
                  <div class="input-group">
                    <span class="input-group-text bg-white border-end-0 text-muted">http://goon.co.id/</span>
                    <input type="text" class="form-control border-start-0 ps-0" placeholder="Post Title" v-model="slug" />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Page Template</label>
                  <v-select v-model="page.template" :options="optionsTemplate" :clearable="false"
                    @input="pageTemplateSelected"></v-select>
                  <span class="text-danger" v-if="$v.page.template.$error">Page Template is required</span>
                </div>
              </div>
              <div class="col-md-12" v-if="activeTemplate === 'General page'">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-15px">
                      <ckeditor v-model="page.content" :config="{
                        delayDetached: true,
                        autoParagraph: false,
                        enterMode: 2
                      }" />
                      <span class="text-danger" v-if="$v.page.content.$error">Content is required</span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label class="form-label">Main Image</label>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <p class="mb-2">
                        (dekstop version : 1280x550 px, max. 1mb, jpeg, jpg, png)
                      </p>
                      <UploadImage v-model="page.images.desktop" :src="page.images.desktopSrc" size="1000"
                        @image-err-message="imgErrMessage" :dimensions="{ width: 1280, height: 550 }" />
                      <!-- <span class="text-danger" v-if="$v.page.images.desktop.$error">Dekstop version is required</span> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <p class="mb-2">
                        (mobile version : 390x500 px, max. 1mb, jpeg, jpg, png)
                      </p>
                      <UploadImage v-model="page.images.mobile" :src="page.images.mobileSrc" size="1000"
                        :dimensions="{ width: 390, height: 500 }" @image-err-message="imgErrMessage" />
                      <!-- <span class="text-danger" v-if="$v.page.images.mobile.$error">Mobile version is required</span> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Dekstop Alt Text</label>
                      <input type="text" v-model="page.images.alt_desktop_image" class="form-control"
                        placeholder="Alt text" />
                      <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Mobile Alt Text</label>
                      <input type="text" v-model="page.images.alt_mobile_image" class="form-control"
                        placeholder="Alt text" />
                      <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                    </div>
                  </div>
                  <!-- <div class="col-md-12">
                    <div class="mb-15px">
                      <label class="form-label">Main Image Alt Text</label>
                      <input type="text" v-model="page.images.alt" class="form-control" placeholder="Alt text" />
                      <span class="text-danger" v-if="$v.page.images.alt.$error">Main Image Alt is required</span>
                    </div>
                  </div> -->
                  <div class="col-md-12">
                    <div class="mb-15px">
                      <label class="form-label">Link Youtube</label>
                      <div class="input-group">
                        <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
                        <input type="url" v-model="page.youtube" class="form-control"
                          placeholder="https://www.youtube.com/@GoonIndonesia" />
                      </div>
                      <!-- <span class="text-danger" v-if="$v.page.youtube.$error">Link Youtube is required</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <panel title="SEO Information" variant="inverse" :is-collapse="true">
          <div class="mb-15px">
            <label class="form-label">Meta Title</label>
            <input type="text" v-model="page.meta.title" placeholder="Meta Title" class="form-control" />
            <span class="text-danger" v-if="$v.page.meta.title.$error">Meta Title is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Description</label>
            <textarea class="form-control" rows="7" v-model="page.meta.description"
              placeholder="Meta Description"></textarea>
            <span class="text-danger" v-if="$v.page.meta.description.$error">Meta Description is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Keywords</label>
            <input-tag v-model="page.meta.keywords" class="form-control default"></input-tag>
            <small class="fw-normal text-muted">Separate with commas</small>
            <br>
            <span class="text-danger" v-if="$v.page.meta.keywords.$error">Meta Keywords is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Image
              <span class="fw-normal text-muted">(Optional)(jpeg, jpg, png)</span></label>
            <UploadImage v-model="page.meta.image" :src="page.meta.imageSrc" size="1000"
              @image-err-message="imgErrMessage" />
            <!-- <span class="text-danger" v-if="$v.page.meta.keywords.$error">Meta Image is required</span> -->
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Alt</label>
              <input type="text" v-model="page.meta.alt_image" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
        </panel>
      </div>
      <div v-if="page.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ page.publishedDate ? dateFormat(page.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ page.lastModified ? dateFormat(page.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ page.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue";
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      optionsTemplate: ["General page"],
      activeTemplate: "General page",
      customToolbar: defaultToolbarEditor,
      page: {
        title: null,
        template: "General page",
        slug: null,
        content: null,
        images: {
          desktop: null,
          desktopSrc: '',
          mobile: null,
          mobileSrc: '',
          alt: null,
        },
        youtube: null,
        meta: {
          title: null,
          description: null,
          keywords: [],
          image: null,
          imageSrc: ''
        },
        publishedDate: null,
        lastModified: null,
        author: null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    slug: function () {
      if (this.page.title) {
        return this.slugify(this.page.title);
      }
    },
  },
  watch: {
    'detailData'(newVal) {
      // console.log(newVal)
      this.page = newVal
    }
  },
  mounted() {
    this.checkSelectedTemplate(this.page.template);
  },
  methods: {
    pageTemplateSelected(value) {
      this.checkSelectedTemplate(value);
    },
    checkSelectedTemplate(value) {
      this.activeTemplate = "General page";
    },
    doSave(status) {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          ...this.page,
          slug: this.slug,
          status: status
        };
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },

    slugify(text, ampersand = "and") {
      if (text == null) {
        return null;
      }

      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },

    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
  },
  validations: {
    slug: { required },
    page: {
      title: { required },
      template: { required },
      content: { required },
      meta: {
        title: { required },
        description: { required },
        keywords: { required }
      },
    },
  }
};
</script>
