<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Reward
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Diskon 10% Semua Produk"
            v-model="form.title" />
          <span class="text-danger" v-if="$v.form.title.$error">Title is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Reward Type</label>
                  <v-select :options="optionsRewards" placeholder="Voucher" label="label" v-model="form.reward_type" />
                  <span class="text-danger" v-if="$v.form.reward_type.$error">Reward type is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Main Image</label>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <p class="mb-2">(dekstop version : 1280x550 px, max. 1mb, jpeg, jpg, png)</p>
                  <UploadImage v-model="form.images.desktop" :src="form.images.desktopSrc" size="1000"
                    @image-err-message="imgErrMessage" />
                  <span class="text-danger" v-if="$v.form.images.desktop.$error">dekstop image is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <p class="mb-2">(mobile version : 600x300 px, max. 1mb, jpeg, jpg, png)</p>
                  <UploadImage v-model="form.images.mobile" :src="form.images.mobileSrc" size="1000"
                    @image-err-message="imgErrMessage" />
                  <span class="text-danger" v-if="$v.form.images.mobile.$error">mobile image is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Dekstop Alt Text</label>
                  <input type="text" v-model="form.images.alt_desktop_image" class="form-control"
                    placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Mobile Alt Text</label>
                  <input type="text" v-model="form.images.alt_mobile_image" class="form-control" placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" v-if="cekRewardType">
                <div class="mb-15px">
                  <label class="form-label">Google Form Url Link</label>
                  <input type="text" v-model="form.google_form_url" class="form-control"
                    placeholder="https://docs.google.com/forms/" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Periode
                    <span class="text-primary">(Dari Tanggal)</span></label>
                  <div class="input-group">
                    <date-picker v-model="form.date.start" :config="options"></date-picker>
                    <span class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                  <span class="text-danger" v-if="$v.form.date.start.$error">Date Start is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Periode
                    <span class="text-primary">(Sampai Tanggal)</span></label>
                  <div class="input-group">
                    <date-picker v-model="form.date.end" :config="options"></date-picker>
                    <span class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                  <span class="text-danger" v-if="$v.form.date.end.$error">Date End is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Stock Inventory</label>
                  <input type="number" class="form-control" placeholder="Stock Invetory" v-model="form.stockInventory" />
                  <span class="text-danger" v-if="$v.form.stockInventory.$error">Stock Inventory is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Point Charge</label>
                  <input type="number" class="form-control" placeholder="Point Charge" v-model="form.pointCharge" />
                  <span class="text-danger" v-if="$v.form.pointCharge.$error">Point Charge is required</span>
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Excerpt</label>
                <ckeditor v-if="form.excerpt && this.$route.params.id" v-model="form.excerpt" :config="{
                  delayDetached: true,
                  autoParagraph: false,
                  enterMode: 2
                }" />

                <ckeditor v-else-if="!this.$route.params.id" v-model="form.excerpt" :config="{
                  delayDetached: true,
                  autoParagraph: false,
                  enterMode: 2
                }" />
              </div>
              <div class="col-md-12">
                <label class="form-label">Description</label>
                <ckeditor v-if="form.excerpt" v-model="form.description" :config="{
                  delayDetached: true,
                  autoParagraph: false,
                  enterMode: 2
                }" />

                <ckeditor v-else-if="!this.$route.params.id" v-model="form.excerpt" :config="{
                  delayDetached: true,
                  autoParagraph: false,
                  enterMode: 2
                }" />
                <span class="text-danger" v-if="$v.form.description.$error">Description is required</span>
              </div>
            </div>
          </div>
        </div>
        <panel title="SEO Information" variant="inverse" :is-collapse="true">
          <div class="mb-15px">
            <label class="form-label">Meta Title</label>
            <input type="text" v-model="form.meta.title" placeholder="Meta Title" class="form-control" />
            <span class="text-danger" v-if="$v.form.meta.title.$error">Meta Title is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Description</label>
            <textarea class="form-control" rows="7" v-model="form.meta.description"
              placeholder="Meta Description"></textarea>
            <span class="text-danger" v-if="$v.form.meta.description.$error">Meta Description is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Keywords</label>
            <input-tag v-model="form.meta.keywords" class="form-control default"></input-tag>
            <small class="fw-normal text-muted">Separate with commas</small>
            <br>
            <span class="text-danger" v-if="$v.form.meta.keywords.$error">Meta Keywords is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Image
              <span class="fw-normal text-muted">(Optional)(jpeg, jpg, png)</span></label>
            <UploadImage v-model="form.meta.image" :src="form.meta.imageSrc" size="1000"
              @image-err-message="imgErrMessage" />
            <!-- <span class="text-danger" v-if="$v.page.meta.keywords.$error">Meta Image is required</span> -->
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Alt</label>
              <input type="text" v-model="form.meta.alt_image" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
        </panel>
      </div>

      <div v-if="form.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ form.publishedDate ? dateFormat(form.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ form.publishedDate ? dateFormat(form.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ form.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue";
import { required } from 'vuelidate/lib/validators'
import dateFormat from "@/pages/Helper/formate_date";
import CKEditor from 'ckeditor4-vue';

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    // console.log(detail);
    return {
      options: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
      optionsRewards: [
        {
          label: 'Voucher',
          value: 'voucher'
        },
        {
          label: 'Physical Goods',
          value: 'physical_goods'
        },
        {
          label: 'Point',
          value: 'point'
        },
        {
          label: 'Free Sample',
          value: 'free_sample'
        },
        {
          label: 'Main Free Sample',
          value: 'main_free_sample'
        }
      ],
      dateFormat,
      customToolbar: defaultToolbarEditor,
      form: {
        reward_type: detail ? detail.title : null,
        title: detail ? detail.title : null,
        images: {
          mobile: detail ? detail.mobile : null,
          mobileSrc: detail ? detail.mobileSrc : '',
          desktop: detail ? detail.desktop : null,
          desktopSrc: detail ? detail.desktopSrc : '',
        },
        date: {
          start: null,
          end: null,
        },
        meta: {
          title: null,
          description: null,
          keywords: [],
          image: null,
          imageSrc: ''
        },
        stockInventory: null,
        pointCharge: null,
        description: detail ? detail.description : null,
        excerpt: detail ? detail.excerpt : null
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    cekRewardType() {
      return this.form?.reward_type?.value == 'free_sample' ? true : false
    }
  },
  watch: {
    detailData(newVal) {
      let { reward_type, excerpt } = newVal
      let findOptionsRewards = this.optionsRewards.find((r) => r.value == reward_type)

      this.form = newVal;
      this.form.reward_type = findOptionsRewards;
    },
  },
  methods: {
    doSave(status) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          form: this.form,
          status
        };
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
  },
  validations: {
    form: {
      title: { required },
      reward_type: { required },
      images: {
        mobile: { required },
        desktop: { required },
      },
      date: {
        start: { required },
        end: { required },
      },
      stockInventory: { required },
      pointCharge: { required },
      description: { required },
      meta: {
        title: { required },
        description: { required },
        keywords: { required }
      },
    },
  }
};
</script>
