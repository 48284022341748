<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">About Us Management</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <panel title="Section 1 - Header" variant="inverse" :is-collapse="true"
          v-model="page.homepage.sections.one.isShow">
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Main Image</label>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <p class="mb-2">
                  (dekstop version : 1240px x 367px, max. 1mb, (jpeg, jpg, png))
                </p>
                <UploadImage v-model="page.homepage.sections.one.images.desktop"
                  :src="page.homepage.sections.one.images.desktopSrc" size="1000" @image-err-message="imgErrMessage"
                  :dimensions="{ width: 1240, height: 367 }" />
                <span class="text-danger" v-if="$v.page.homepage.sections.one.images.desktop.$error">Dekstop Image is
                  required</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <p class="mb-2">
                  (mobile version : 351px x 363px, max. 1mb, (jpeg, jpg, png))
                </p>
                <UploadImage v-model="page.homepage.sections.one.images.mobile"
                  :src="page.homepage.sections.one.images.mobileSrc" size="1000" @image-err-message="imgErrMessage"
                  :dimensions="{ width: 351, height: 363 }" />
                <span class="text-danger" v-if="$v.page.homepage.sections.one.images.mobile.$error">Mobile Image is
                  required</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-15px">
                <label class="form-label">Dekstop Alt Text</label>
                <input type="text" v-model="page.homepage.sections.one.images.alt_desktop_image" class="form-control"
                  placeholder="Alt text" />
                <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <label class="form-label">Mobile Alt Text</label>
                <input type="text" v-model="page.homepage.sections.one.images.alt_mobile_image" class="form-control"
                  placeholder="Alt text" />
                <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-15px">
                <label class="form-label">Heading</label>
                <input type="text" class="form-control"
                  placeholder="Kamu adalah Kehidupan Kecil yang Terlahir untuk Dicintai"
                  v-model="page.homepage.sections.one.heading" />
                <span class="text-danger" v-if="$v.page.homepage.sections.one.heading.$error">Heading is required</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <label class="form-label">Subheading</label>
                <input type="text" class="form-control" placeholder="Brand Statement"
                  v-model="page.homepage.sections.one.subheading" />
                <span class="text-danger" v-if="$v.page.homepage.sections.one.subheading.$error">Subheading is
                  required</span>
              </div>
            </div>
          </div>
          <div class="mb-15px">
            <label class="form-label">Description </label>
            <ckeditor v-model="page.homepage.sections.one.content" :config="{
    delayDetached: true,
    autoParagraph: false,
    enterMode: 2
  }" />
            <span class="text-danger" v-if="$v.page.homepage.sections.one.content.$error">Description is required</span>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button type="button" class="btn btn-outline-primary" @click.prevent="doSave(1, 'Draft')">
                <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                DRAFT
              </button>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary" @click.prevent="doSave(1, 'Publish')">
                <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                SAVE
              </button>
            </div>
          </div>
        </panel>

        <panel title="Section 2 - Tentang Kami" variant="inverse" :is-collapse="true"
          v-model="page.homepage.sections.two.isShow">
          <!-- BEGIN nav-tabs -->
          <b-tabs nav-wrapper-class="nav nav-tabs nav-tabs--wp" content-class="py-15px bg-white">
            <!-- BEGIN tab -->
            <b-tab :title="`TAB ${parseInt(index) + 1}`"
              v-for="(item, index) in $v.page.homepage.sections.two.tabs.$each.$iter" :active="parseInt(index) === 0"
              :key="parseInt(index)">
              <div class="panel panel-grey">
                <div class="panel-body">

                  <div class="mb-15px">
                    <label class="form-label">Icon Tab {{ parseInt(index) + 1 }}</label>
                    <p class="mb-2">
                      landscape (jpeg, jpg, png)
                    </p>
                    <UploadImage v-model="item.icon.$model" size="1000" @image-err-message="imgErrMessage"
                      :src="item.$model.iconSrc" />
                    <span class="text-danger" v-if="item.icon.$error">Icon is required</span>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-15px">
                      <label class="form-label">Alt Icon </label>
                      <input type="text" v-model="item.$model.alt_icon" class="form-control" placeholder="Alt text" />
                      <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                    </div>
                  </div>
                  <div class="mb-15px">

                    <panel v-for="(item, indexContent) in  item.subContent.$each.$iter " :key="parseInt(indexContent)"
                      :index="parseInt(indexContent)" :title="`Item - ${parseInt(indexContent) + 1}`" variant="grey"
                      :is-collapse="true" :checkbox-hidden="false" :has-remove="true" :show-collapse="true"
                      :checkbox-name="`step-${parseInt(indexContent)}`"
                      @onRemove="removeContent(parseInt(index), parseInt(indexContent))">
                      <div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-15px">
                              <label class="form-label">Title {{ parseInt(indexContent) + 1 }}</label>
                              <input type="text" placeholder="Dedicated" v-model="item.title.$model"
                                class="form-control" />
                              <span class="text-danger" v-if="item.title.$error">Title is required</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-15px">
                              <label class="form-label">Subtitle {{ parseInt(indexContent) + 1 }}</label>
                              <input type="text" placeholder="" v-model="item.subtitle.$model" class="form-control" />
                              <span class="text-danger" v-if="item.subtitle.$error">Subtitle is required</span>
                            </div>
                          </div>
                        </div>
                        <div class="mb-15px">
                          <label class="form-label">Description {{ parseInt(indexContent) + 1 }}</label>
                          <ckeditor v-model="item.description.$model" :id="`editor - ${parseInt(indexContent)}`"
                            :config="{
    delayDetached: true,
    autoParagraph: false,
    enterMode: 2
  }" />
                          <span class="text-danger" v-if="item.description.$error">Description is required</span>
                        </div>
                        <div class="mb-15px">
                          <label class="form-label">Image Content {{ parseInt(indexContent) + 1 }}</label>
                          <p class="mb-2">(Main Image 650x264px, (jpeg, jpg, png))</p>
                          <UploadImage v-model="item.image.$model" size="1000" @image-err-message="imgErrMessage"
                            :src="item.$model.imageSrc" :dimensions="{ width: 650, height: 264 }" />
                          <span class="text-danger" v-if="item.image.$error">Image is required</span>
                        </div>
                        <div class="col-md-12">
                          <div class="mb-15px">
                            <label class="form-label">Alt Image </label>
                            <input type="text" v-model="item.alt_image.$model" class="form-control"
                              placeholder="Alt text" />
                            <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                          </div>
                        </div>
                      </div>
                    </panel>
                    <div class="mb-15px">
                      <button @click="addSubContent(index)" class="btn btn-outline-primary w-100 d-block text-start"
                        type="button">
                        <i class="fa-solid fa-plus"></i> Add Another Desription Item
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <panel v-for="( item, indexSubtitle ) in  item.items.$each.$iter " :key="parseInt(indexSubtitle)"
                :index="parseInt(indexSubtitle)" :title="`Item - ${parseInt(indexSubtitle) + 1}`" variant="grey"
                :is-collapse="true" :checkbox-hidden="false" :has-remove="true" :show-collapse="true"
                :checkbox-name="`step-${parseInt(indexSubtitle)}`"
                @onRemove="removeItemsTop(parseInt(index), parseInt(indexSubtitle))">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Title {{ parseInt(indexSubtitle) + 1 }}</label>
                      <input type="text" placeholder="Dedicated" v-model="item.title.$model" class="form-control" />
                      <span class="text-danger" v-if="item.title.$error">Title is required</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <label class="form-label">Subtitle {{ parseInt(indexSubtitle) + 1 }}</label>
                      <input type="text" placeholder="" v-model="item.subtitle.$model" class="form-control" />
                      <span class="text-danger" v-if="item.subtitle.$error">Subtitle is required</span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label class="form-label">Description {{ parseInt(indexSubtitle) + 1 }}</label>
                    <textarea rows="7" v-model="item.description.$model" class="form-control"></textarea>
                    <span class="text-danger" v-if="item.description.$error">Description is required</span>
                  </div>
                </div>
              </panel>
              <div class="mb-15px">
                <button @click="addItemsTop(index)" class="btn btn-outline-primary w-100 d-block text-start"
                  type="button">
                  <i class="fa-solid fa-plus"></i> Add Another Item
                </button>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Title Section</label>
                  <input type="text" placeholder="" v-model="item.section_title.$model" class="form-control" />
                  <!-- <span class="text-danger" v-if="item.subtitle.$error">Subtitle is required</span> -->
                </div>
              </div>
              <panel v-for="( item, indexItem ) in  page.homepage.sections.two.tabs[index].contentItems "
                :key="'contentItem-' + parseInt(indexItem)" :index="'contentItem-' + parseInt(indexItem)"
                :title="`Content Item No. ${item.fieldNo}`" variant="grey" :is-collapse="true" :checkbox-hidden="false"
                :show-collapse="true" :has-remove="true" @onRemove="removeItems(parseInt(index), parseInt(indexItem))"
                :checkbox-name="`itemContent-${parseInt(indexItem)}`">
                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-15px">
                      <label class="form-label">Item no. {{ item.fieldNo }}</label>
                      <input type="text" placeholder="" v-model="item.fieldNo" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-15px">
                      <label class="form-label">Description</label>
                      <textarea type="text" placeholder="" v-model="item.description" class="form-control"
                        rows="7"></textarea>
                    </div>
                  </div>
                </div>
              </panel>

              <div class="mb-15px">
                <button @click="addItems(index)" class="btn btn-outline-primary w-100 d-block text-start" type="button">
                  <i class="fa-solid fa-plus"></i> Add Another Item
                </button>
              </div>
            </b-tab>
            <!-- END tab -->
          </b-tabs>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button type="button" class="btn btn-outline-primary" @click.prevent="doSave(2, 'Draft')">
                <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                DRAFT
              </button>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary" @click.prevent="doSave(2, 'Publish')">
                <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                SAVE
              </button>
            </div>
          </div>
        </panel>

        <panel title="Section 3 - History" variant="inverse" :is-collapse="true"
          v-model="page.homepage.sections.three.isShow">
          <panel v-for="( item, index ) in $v.page.homepage.sections.three.steps.$each.$iter" :key="parseInt(index)"
            :index="parseInt(index)" :title="`Step ${parseInt(index) + 1}`" variant="grey" :is-collapse="true"
            :checkbox-hidden="false" :show-collapse="parseInt(index) > 0 ? false : true"
            :checkbox-name="`step-${parseInt(index)}`" :has-remove="true" @onRemove="removeSteps(parseInt(index))">
            <div class="mb-15px">
              <div class="row align-items-center">
                <div class="col-auto">
                  <label class="form-label">Year</label>
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" placeholder="" v-model="item.year.$model" :maxlength="4"
                    v-on:keypress="isNumber" />
                  <span class="text-danger" v-if="item.year.$error">Year is required</span>
                </div>
              </div>
            </div>
            <div class="mb-15px">
              <label class="form-label">Description {{ parseInt(index) + 1 }}</label>
              <ckeditor v-model="item.description.$model" :config="{
    delayDetached: true,
    autoParagraph: false,
    enterMode: 2
  }" />
              <span class="text-danger" v-if="item.year.$error">Description is required</span>
            </div>
          </panel>
          <div class="mb-15px">
            <button @click="addSteps()" class="btn btn-outline-primary w-100 d-block text-start" type="button">
              <i class="fa-solid fa-plus"></i> Add Another Step
            </button>
          </div>
          <div class="text-danger" v-if="$v.page.homepage.sections.three.steps.$error">
            Name must have at least
            {{ $v.page.homepage.sections.three.steps.$params.minLength.min }}
            letters.
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button type="button" class="btn btn-outline-primary" @click.prevent="doSave(3, 'Draft')">
                <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                DRAFT
              </button>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary" @click.prevent="doSave(3, 'Publish')">
                <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                SAVE
              </button>
            </div>
          </div>
        </panel>
        <panel title="Section 4 - Video About Us" variant="inverse" :is-collapse="true"
          v-model="page.homepage.sections.four.isShow">
          <div class="mb-15px">
            <label class="form-label">ID Video YouTube</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
              <input type="url" v-model="page.homepage.sections.four.youtube" class="form-control" placeholder="" />
            </div>
            <p class="mb-2">https://www.youtube.com/watch?v=XXXX, XXXX adalah id youtube Link</p>
            <span class="text-danger" v-if="$v.page.homepage.sections.four.youtube.$error">Youtube is required</span>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button type="button" class="btn btn-outline-primary" @click.prevent="doSave(4, 'Draft')">
                <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                DRAFT
              </button>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary" @click.prevent="doSave(4, 'Publish')">
                <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                SAVE
              </button>
            </div>
          </div>
        </panel>
        <panel title="SEO Information" variant="inverse" :is-collapse="true">
          <div class="mb-15px">
            <label class="form-label">Meta Title</label>
            <input type="text" v-model="page.meta.title" placeholder="Meta Title" class="form-control" />
            <span class="text-danger" v-if="$v.page.meta.title.$error">Meta Title is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Description</label>
            <textarea class="form-control" rows="7" v-model="page.meta.description"
              placeholder="Meta Description"></textarea>

            <span class="text-danger" v-if="$v.page.meta.description.$error">Meta Description is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Keywords</label>
            <input-tag v-model="page.meta.keywords" class="form-control default"></input-tag>
            <small class="fw-normal text-muted">Separate with commas</small>
            <br>
            <span class="text-danger" v-if="$v.page.meta.keywords.$error">Meta Keywords is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Image
              <span class="fw-normal text-muted">(Optional)</span></label>
            <UploadImage v-model="page.meta.image" :src="page.meta.imageSrc" />
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Alt Image </label>
              <input type="text" v-model="page.meta.alt_image" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button type="submit" class="btn btn-primary" @click.prevent="doSave(5, 'Seo')">
                <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                SAVE
              </button>
            </div>
          </div>
        </panel>
      </div>

      <div v-if="page.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">
                {{ page.publishedDate ? dateFormat(page.publishedDate) : "" }}
              </p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">
                {{ page.lastModified ? dateFormat(page.lastModified) : "" }}
              </p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ page.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import aboutusService from "@/service/aboutus.service";
import dateFormat from "@/pages/Helper/formate_date";
import { minLength, required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      id: null,
      customToolbar: defaultToolbarEditor,
      dateFormat,
      page: {
        title: detail ? detail.title : null,
        template: detail ? detail.template : "Homepage",
        publishedDate: null,
        lastModified: null,
        author: null,
        meta: {
          title: detail ? detail.meta.title : null,
          description: detail ? detail.meta.description : null,
          keywords: detail ? detail.meta.keywords : [],
          image: detail ? detail.meta.image : null,
        },
        homepage: {
          sections: {
            one: {
              isShow: true,
              heading: "",
              subheading: "",
              content: "",
              images: {
                desktopSrc: "",
                desktop: null,
                mobileSrc: "",
                mobile: null,
              },
            },
            two: {
              isShow: true,
              tabs: [
                {
                  section_title: '',
                  id: 0,
                  icon: null,
                  iconSrc: "",
                  image: null,
                  imageSrc: "",
                  description: "",
                  items: [
                    {
                      id: 0,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                    {
                      id: 1,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                    {
                      id: 2,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                    {
                      id: 3,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                  ],
                  subContent: [
                    {
                      description: '',
                      images: null,
                      imagesSrc: ''
                    }
                  ],
                  contentItems: [
                    {
                      id: 0,
                      fieldNo: "",
                      description:
                        ""
                    },
                  ],
                },
                {
                  section_title: '',
                  id: 1,
                  icon: null,
                  iconSrc: "",
                  image: null,
                  imageSrc: "",
                  description1: "",
                  description2: "",
                  items: [
                    {
                      id: 0,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                    {
                      id: 1,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                    {
                      id: 2,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                    {
                      id: 3,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                  ],
                  subContent: [
                    {
                      description: '',
                      images: null,
                      imagesSrc: ''
                    }
                  ],
                  contentItems: [
                    {
                      id: 0,
                      fieldNo: "",
                      description:
                        ""
                    },
                  ],
                },
                {
                  section_title: '',
                  id: 2,
                  icon: null,
                  iconSrc: "",
                  image: null,
                  imageSrc: "",
                  description1: "",
                  description2: "",
                  items: [
                    {
                      id: 0,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                    {
                      id: 1,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                    {
                      id: 2,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                    {
                      id: 3,
                      title: "",
                      subtitle: "",
                      description: "",
                    },
                  ],
                  subContent: [
                    {
                      description: '',
                      images: null,
                      imagesSrc: ''
                    }
                  ],
                  contentItems: [
                    {
                      id: 0,
                      fieldNo: "",
                      description:
                        ""
                    },
                  ],
                },
              ],
            },
            three: {
              isShow: true,
              steps: [
                {
                  id: 0,
                  year: "",
                  description: "",
                },
                {
                  id: 1,
                  year: "",
                  description: "",
                },
                {
                  id: 3,
                  year: "",
                  description: "",
                },
              ],
            },
            four: {
              isShow: true,
              youtube: "",
            },
          },
        },
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  methods: {
    addSteps() {
      this.page.homepage.sections.three.steps.push({
        id: "",
        year: "",
        description: "",
      });
    },
    removeSteps(index) {
      this.page.homepage.sections.three.steps.splice(index, 1);
    },
    addSubContent(indexTab) {
      this.page.homepage.sections.two.tabs[indexTab].subContent.push({
        title: '',
        subtitle: '',
        description: "",
        image: null,
        imageSrc: "",
      });
    },
    addItems(tabIndex) {
      this.page.homepage.sections.two.tabs[tabIndex].contentItems.push({
        id: "",
        fieldNo: "",
        description: "",
      });
    },
    addItemsTop(tabIndex) {
      this.page.homepage.sections.two.tabs[tabIndex].items.push({
        id: 0,
        title: "",
        subtitle: "",
        description: "",
      },);
    },
    removeItems(tabIndex, index) {
      this.page.homepage.sections.two.tabs[tabIndex].contentItems.splice(
        index,
        1
      );
    },
    removeItemsTop(tabIndex, index) {
      this.page.homepage.sections.two.tabs[tabIndex].items.splice(
        index,
        1
      );
    },
    removeContent(tabIndex, index) {
      this.page.homepage.sections.two.tabs[tabIndex].subContent.splice(
        index,
        1
      );
    },
    doSave(section, status) {
      var invalid = null;
      switch (section) {
        case 1:
          this.$v.page.homepage.sections.one.$touch();
          invalid = this.$v.page.homepage.sections.one.$invalid;
          break;
        case 2:
          this.$v.page.homepage.sections.two.$touch();
          invalid = this.$v.page.homepage.sections.two.$invalid;
          break;
        case 3:
          this.$v.page.homepage.sections.three.$touch();
          invalid = this.$v.page.homepage.sections.three.$invalid;
          break;
        case 4:
          this.$v.page.homepage.sections.four.$touch();
          invalid = this.$v.page.homepage.sections.four.$invalid;
          break;
        case 5:
          this.$v.page.meta.$touch();
          invalid = this.$v.page.meta.$invalid;
          break;
        default:
          break;
      }

      if (!invalid) {
        const payload = {
          id: this.id,
          section: section,
          data: this.page.homepage.sections,
          seo: this.page.meta,
          status,
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    loadItems() {
      aboutusService.getAboutUsList({}).then(async ({ data: { data } }) => {
        if (data.length > 0) {
          let row = data[0];
          this.id = data[0].id;

          for (const key in row) {
            switch (key) {
              case "Section1":
                if (row[key]) {
                  this.page.homepage.sections.one = {
                    heading:
                      row[key].heading
                        ? row[key].heading
                        : "",
                    subheading:
                      row[key].sub_heading
                        ? row[key].sub_heading
                        : "",
                    content:
                      row[key].description
                        ? row[key].description
                        : "",
                    images: {
                      desktopSrc:
                        row[key].desktop_image
                          ? this.loadSrcImage(row[key].desktop_image)
                          : "",
                      desktop:
                        row[key].desktop_image
                          ? [
                            await this.convertURLtoFile(
                              this.loadSrcImage(row[key].desktop_image)
                            ),
                          ]
                          : null,
                      mobileSrc:
                        row[key].mobile_image
                          ? this.loadSrcImage(row[key].mobile_image)
                          : "",
                      mobile:
                        row[key].mobile_image
                          ? [
                            await this.convertURLtoFile(
                              this.loadSrcImage(row[key].mobile_image)
                            ),
                          ]
                          : null,
                      alt_desktop_image: row[key].alt_desktop_image ? row[key].alt_desktop_image : null,
                      alt_mobile_image: row[key].alt_mobile_image ? row[key].alt_mobile_image : null,
                    }
                  }
                };
                break;
              case "Section2":
                let sections2 = [];
                if (Object.keys(row[key]).length > 0) {
                  for (const [k, v] of Object.entries(this.page.homepage.sections.two.tabs)) {
                    if (row[key][k]) {
                      let items = [], contentItem = [], addNew = [];
                      for (const [k1, v1] of Object.entries(row[key][k].Section2Item)) {
                        items.push({
                          title: v1.title ? v1.title : "",
                          subtitle: v1.sub_title ? v1.sub_title : "",
                          description: v1.description ? v1.description : "",
                        });
                      }
                      for (const [k1, v1] of Object.entries(row[key][k].Section2AddNew)) {
                        addNew.push({
                          title: v1.title ? v1.title : null,
                          subtitle: v1.sub_title ? v1.sub_title : null,
                          description: v1.description ? v1.description : "",
                          image: v1.image ? [await this.convertURLtoFile(this.loadSrcImage(v1.image)),] : null,
                          imageSrc: v1.image ? this.loadSrcImage(v1.image) : "",
                          alt_image: v1.alt_image ? v1.alt_image : null,
                        });
                      }
                      for (const [k1, v1] of Object.entries(row[key][k].Section2ContentItem)) {
                        contentItem.push({
                          fieldNo: v1.no ? v1.no : "",
                          description: v1.description
                            ? v1.description
                            : null,
                        });
                      }
                      sections2.push({
                        icon: row[key][k].icon ? [await this.convertURLtoFile(this.loadSrcImage(row[key][k].icon)),] : null,
                        iconSrc: row[key][k].icon ? this.loadSrcImage(row[key][k].icon) : "",
                        image: row[key][k].image ? [await this.convertURLtoFile(this.loadSrcImage(row[key][k].image)),] : null,
                        imageSrc: row[key][k].image ? this.loadSrcImage(row[key][k].image) : "",
                        description1: row[key][k].description1 ? row[key][k].description1 : "",
                        description2: row[key][k].description2 ? row[key][k].description2 : "",
                        section_title: row[key][k].title_section ? row[key][k].title_section : null,
                        alt_icon: row[key][k].alt_icon ? row[key][k].alt_icon : null,
                        items: items,
                        contentItems: contentItem,
                        subContent: addNew
                      });
                    } else {
                      sections2.push(v);
                    }
                  }
                  this.page.homepage.sections.two.tabs = sections2;
                }
                break;
              case "Section3":
                let sections3 = [];

                if (Object.keys(row[key]).length > 0) {
                  for (const [k, v] of Object.entries(row[key])) {
                    sections3.push({
                      year: v.year ? v.year : "",
                      description: v.description ? v.description : "",
                    });
                  }
                  this.page.homepage.sections.three.steps = sections3;
                }
                break;
              case "Section4":
                if (row[key]) {
                  this.page.homepage.sections.four.youtube =
                    row[key].link_youtube
                      ? row[key].link_youtube
                      : "";
                }
                break;
              case "SeoInformation":
                if (row[key]) {
                  this.page.meta = {
                    title: (row[key]?.meta_title && row[key]) ? row[key].meta_title : null,
                    description: (row[key]?.meta_description && row[key]) ? row[key].meta_description : null,
                    keywords: (row[key]?.meta_keyword && row[key]) ? row[key].meta_keyword.split(',') : [],
                    image: (row[key]?.image && row[key]) ? [await this.convertURLtoFile(this.loadSrcImage(row[key].image))] : null,
                    imageSrc: (row[key]?.image && row[key]) ? this.loadSrcImage(row[key].image) : '',
                    alt_image: row[key].alt_image ? row[key].alt_image : null
                  }
                }
                break;
              default:
                break;
            }
          }
          this.page.publishedDate = row.publishedDate
            ? row.publishedDate
            : null;
          this.page.lastModified = row.updatedAt ? row.updatedAt : null;
          this.page.author = row.User && row.User?.name ? row.User?.name : '';
        }
      });
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    loadSrcImage(url) {
      return this.$api_name + "/" + url;
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
  mounted() {
    this.loadItems();
    console.log("!!!");
  },
  validations: {
    page: {
      homepage: {
        sections: {
          one: {
            heading: { required },
            subheading: { required },
            content: { required },
            images: {
              desktop: { required },
              mobile: { required },
            },
          },
          two: {
            tabs: {
              minLength: minLength(1),
              $each: {
                alt_icon: {},
                icon: {},
                section_title: {},
                subContent: {
                  $each: {
                    title: {},
                    subtitle: {},
                    description: {},
                    image: {},
                    alt_image: {}
                  }
                },
                items: {
                  $each: {
                    title: {
                    },
                    subtitle: {
                    },
                    description: {
                    },
                  },
                },
              },
            },
          },
          three: {
            steps: {
              $each: {
                year: {},
                description: {},
              },
            },
          },
          four: {
            youtube: {},
          },
        },
      },
      meta: {
        title: { required },
        description: { required },
        keywords: { required },
      },
    },
  },
};
</script>
