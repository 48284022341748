<template>
  <div class="page-settings">
    <form @submit.prevent="doSave" class="pb-5 mb-4">
      <div class="row align-items-center justify-content-between mb-4">
        <div class="col-auto">
          <!-- BEGIN page-header -->
          <h1 class="page-header">Site Setting</h1>
          <!-- END page-header -->
        </div>
        <div class="col-auto mb-3">
          <button class="btn btn-outline-primary" @click.prevent="clearCache">Clear Cache</button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9 col-md-8">
          <setting-nav />
          <div class="panel">
            <div class="panel-heading bg-light">
              <h4 class="panel-title">Site Title</h4>
            </div>
            <div class="panel-body">
              <label class="form-label">
                This is your site name
              </label>
              <input type="text" v-model="form.name" class="form-control" placeholder="Goon Indonesia">
              <span class="text-danger" v-if="$v.form.name.$error">Name is
                required</span>
            </div>
            <div class="panel-heading bg-light">
              <h4 class="panel-title">Site Description</h4>
            </div>
            <div class="panel-body">
              <ckeditor v-model="form.description" :config="{
      delayDetached: true,
      autoParagraph: false,
      enterMode: 2
    }" />
              <span class="text-danger" v-if="$v.form.description.$error">Description is
                required</span>
            </div>
            <div class="panel-heading bg-light">
              <h4 class="panel-title">Site FAVICON (jpeg, jpg, png)</h4>
            </div>
            <div class="panel-body">
              <UploadImage v-model="form.favicon" :src="form.faviconSrc" size="1000"
                @image-err-message="imgErrMessage" />
              <span class="text-danger" v-if="$v.form.favicon.$error">FAVICON is
                required</span>
            </div>
            <div class="panel-body">
              <label class="form-label">Alt</label>
              <input type="text" v-model="form.altFavicon" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>

            <div class="panel-heading bg-light">
              <h4 class="panel-title">Company Logo (jpeg, jpg, png)</h4>
            </div>
            <div class="panel-body">
              <UploadImage v-model="form.logo" :src="form.logoSrc" size="1000" @image-err-message="imgErrMessage"
                :dimensions="{ width: 141, height: 64 }" />
              <span class="text-danger" v-if="$v.form.logo.$error">Company Logo is
                required</span>
            </div>
            <div class="panel-body">
              <label class="form-label">Alt</label>
              <input type="text" v-model="form.alt_company_logo" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
            <div class="panel-heading bg-light">
              <h4 class="panel-title">Company Addres</h4>
            </div>
            <div class="panel-body">
              <ckeditor v-model="form.address" :config="{
      delayDetached: true,
      autoParagraph: false,
      enterMode: 2
    }" />
              <span class="text-danger" v-if="$v.form.address.$error">Addres is
                required</span>
            </div>

            <div class="panel-body">
              <label class="form-label">CTA Tiktok</label>
              <input type="text" v-model="form.cta_tiktok" class="form-control" placeholder="Link" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-4">
          <div class="panel panel-sticky">
            <div class="panel-body">
              <div class="mb-15px">
                <p class="fw-bolder mb-1">Published Date</p>
                <p class="mb-0">22 December 2022</p>
              </div>
              <div class="mb-15px">
                <p class="fw-bolder mb-1">Last Modified Date</p>
                <p class="mb-0">22 December 2022</p>
              </div>
              <div class="mb-0">
                <p class="fw-bolder mb-1">Author</p>
                <p class="mb-0">Wanda Vu</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="form-action" v-if="edit">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button type="submit" class="btn btn-primary">
              <span v-show="isLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SettingNav from './Nav.vue'
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue";
import SiteSettingService from "@/service/sitesetting.service";
import CacheService from "@/service/cache.service";
import { required } from 'vuelidate/lib/validators'
import base64Converter from "@/pages/Helper/base64";
import base64ToFile from "@/pages/Helper/base64ToFile";
import tokenService from "@/service/token.service";

export default {
  components: {
    SettingNav,
    UploadImage
  },
  data() {
    const defaultToolbarEditor = defaultToolbar;
    return {
      permisions: tokenService.getPermission(),
      customToolbar: defaultToolbarEditor,
      isLoading: false,
      id: {
        name: null,
        description: null,
        favicon: null,
        address: null,
        logo: null
      },
      form: {
        name: null,
        description: null,
        favicon: null,
        faviconSrc: '',
        logo: null,
        logoSrc: '',
        address: null
      }
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    async doSave() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        let { faviconSrc, logoSrc, ...form } = this.form
        let arrReq = []
        for (const v in form) {
          if (this.id[v]) {
            //Edit
            arrReq.push(
              this.editRequest(
                this.id[v], {
                caller_param: v + '_site',
                title: v + '_site',
                description: v + '_site',
                default_value: (['favicon', 'logo'].includes(v)) ? await base64Converter(form[v][0]) : form[v],
                attribute_value: "",
                type: (['favicon', 'logo'].includes(v)) ? "image" : "text",
                grup: "site",
                company_logo: await base64Converter(this.form.logo[0]),
                company_address: this.form.address,
                alt_company_logo: this.form.alt_company_logo ? this.form.alt_company_logo : null,
                cta_tiktok: this.form.cta_tiktok ? this.form.cta_tiktok : null,
              }))
          } else {
            // insert
            arrReq.push(this.addRequest({
              caller_param: v + '_site',
              title: v + '_site',
              description: v + '_site',
              default_value: (['favicon', 'logo'].includes(v)) ? await base64Converter(form[v][0]) : form[v],
              attribute_value: "",
              type: (['favicon', 'logo'].includes(v)) ? "image" : "text",
              grup: "site",
              company_logo: await base64Converter(this.form.logo[0]),
              company_address: this.form.address,
              cta_tiktok: this.form.cta_tiktok ? this.form.cta_tiktok : null,
            }))
          }
        }

        Promise.all(arrReq)
          .then((r) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.loadItems();
            });
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else if (e.response?.status === 403) {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: "Permision Denied",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          })
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    loadItems() {
      // this.isLoading = true;
      SiteSettingService
        .getSiteSettingGrup('site')
        .then(async ({ data: { data } }) => {
          console.log(data);
          if (data.length > 0) {
            for (const [k, v] of Object.entries(data)) {
              let { id, caller_param, default_value, type, cta_tiktok } = v
              let key = caller_param.split('_')[0]
              if (type === 'image') {
                let arrFile = default_value.split(',')
                this.form[key + 'Src'] = this.loadSrcImage(default_value)
                this.form[key] = [await this.convertURLtoFile(this.loadSrcImage(default_value))]
                this.id[key] = id
              } else if (key == 'alt') {
                this.form.alt_company_logo = default_value
                this.id.alt_company_logo = id
              } else if (key == 'altfavicon') {
                this.form.altFavicon = default_value
                this.id.altFavicon = id
              } else if (key == 'cta') {
                this.form.cta_tiktok = cta_tiktok
              } else {
                // console.log(key)
                this.form[key] = default_value
                this.id[key] = id
              }
            }
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    addRequest(req) {
      return SiteSettingService
        .postSiteSetting(req)
        .then((response) => {
          return Promise.resolve({ ...response })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
    editRequest(id, req) {
      return SiteSettingService
        .patchSiteSetting(id, req)
        .then((response) => {
          return Promise.resolve({ ...response })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
    clearCache() {
      this.isLoading = true;
      CacheService.postCache().then((r) =>
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.loadItems();
        })).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    }
  },
  computed: {
    edit: function () {
      if (this.permisions.find((r) => r == 'SiteSetting Patch')) {
        return true
      }
      return false;
    },
  },
  validations: {
    form: {
      name: { required },
      description: { required },
      favicon: { required },
      logo: { required },
      address: { required },
    },
  }
};
</script>
